import React from "react";
import styles from './styles/App.module.css';
import Homepage from "./homepage/Homepage";
import { Helmet } from "react-helmet";

export default function App() {
  return (
    <main className={styles.main}>
      <Helmet>
        <title>Xeniapps</title>
        <meta name="description" content="React Native development, available" />
        <link rel="canonical" href="https://www.xeniapps.co.uk" />
      </Helmet>
      <Homepage />
    </main>
  );
}
