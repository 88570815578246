import { useNavigate } from "react-router-dom";
import { getSettlements } from "../../../services/api";
import { useEffect, useState } from "react";

const useSettlements = () => {

    const navigate = useNavigate();

    const [fetchingSettlements, setFetchingSettlements] = useState<boolean>(false);
    const [settlements, setSettlements] = useState<any[]>([]);

    useEffect(() => {
        setFetchingSettlements(true);
        const fetchSettlements = async () => {
            const { status, data } = await getSettlements();
            if (status === 200 && data) {
                setSettlements(data)
            }
        }

        fetchSettlements().then(() => setFetchingSettlements(false)).catch(() => setFetchingSettlements(false))
    }, [])

    return {
        settlements,
        fetchingSettlements,
        navigate,
    }
}

export default useSettlements