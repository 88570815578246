import { clearTokens } from "../../../../../services/tokens";
import { getUserData } from "../../../../../services/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const useHeader = () => {

    const navigate = useNavigate();

    const [profileData, setProfileData] = useState<any>(false)
    const [fetchingUserData, setFetchingUserData] = useState<boolean>(false)

    useEffect(() => {
        setFetchingUserData(true);
        const fetchUserData = async () => {
            const { status, data } = await getUserData();
            if (status === 200 && data) {
                setProfileData(data)
            }
        }

        fetchUserData().then(() => setFetchingUserData(false)).catch(() => setFetchingUserData(false))
    }, [])

    const handlePressXeniapps = () => {
        navigate('/dashboard')
    }

    const handleLogout = async () => {
        await clearTokens();
        navigate('/login')
    }

    return {
        profileData,
        fetchingUserData,
        handlePressXeniapps,
        handleLogout
    }
}

export default useHeader