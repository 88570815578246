import { getSettings, updateSettings } from "../../../services/api";
import { useEffect, useState } from "react";

const useSettings = () => {

	const [fetchingSettings, setFetchingSettings] = useState<boolean>(false);

	const [settings, setSettings] = useState<any>({
		show_update_modal: false,
		last_active_version: '',
	});
	const [showUpdateModalStumblr, setShowUpdateModalStumblr] = useState<boolean>(false);
	const [lastActiveStumblrVersion, setLastActiveStumblrVersion] = useState<string>('');

	const [submittingSettings, setSubmittingSettings] = useState<boolean>(false);

	useEffect(() => {
		setFetchingSettings(true);
		const fetchSettings = async () => {
			const { status, data } = await getSettings();
			if (status === 200 && data) {
				console.log('data: ', data)
				setSettings(data)
				setShowUpdateModalStumblr(data.show_update_modal_stumblr?.value === 'true');
				setLastActiveStumblrVersion(data.last_active_stumblr_version?.value);
			}
		}

		fetchSettings().then(() => setFetchingSettings(false)).catch(() => setFetchingSettings(false))
	}, [])

	const submitSave = async () => {
		setSubmittingSettings(true)
		const params = {
			showUpdateModalStumblr,
			lastActiveStumblrVersion,
		}

		await updateSettings({ params });

		setSubmittingSettings(false)
	}


	return {
		fetchingSettings,
		showUpdateModalStumblr,
		lastActiveStumblrVersion,
		setShowUpdateModalStumblr,
		setLastActiveStumblrVersion,
		submittingSettings,
		submitSave,
		canSubmit: settings.show_update_modal_stumblr?.value !== showUpdateModalStumblr && settings.last_active_stumblr_version?.value !== lastActiveStumblrVersion
	}
}

export default useSettings;