import CustomButton from "../components/buttons/CustomButton"
import FormInput from "../components/form/Input"
import useSettings from "./hooks/useSettings"

const Settings = () => {

	const {
		// showUpdateModalStumblr,
		lastActiveStumblrVersion,
		// setShowUpdateModalStumblr,
		setLastActiveStumblrVersion,
		submittingSettings,
		canSubmit,
		submitSave,
	} = useSettings()

	console.log('canSubmit:', canSubmit)

	return <div >
		<FormInput
			field={'Show Update Modal'}
			value={lastActiveStumblrVersion}
			onChange={(e: any) => setLastActiveStumblrVersion(e.target.value)}
			errors={[]}
			disabled={submittingSettings}
		/>

		<CustomButton
			onClick={submitSave}
			text={"Save"}
			disabled={!canSubmit || submittingSettings}
		/>
	</div>
}

export default Settings