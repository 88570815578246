import { AppInitialStateType } from '@/context/types/AppInitialStateType';

type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  };
};

export enum ContextTypes {
  initializeApp = 'INITIALIZE_APP',
  authToken = 'AUTH_TOKEN',
  profileData = 'PROFILE_DATA',
}

type AppPayload = {
  [ContextTypes.initializeApp]: object;
  [ContextTypes.authToken]: string;
  [ContextTypes.profileData]: unknown | null;
};

export type AppActions = ActionMap<AppPayload>[keyof ActionMap<AppPayload>];

export const appReducer = (state: AppInitialStateType, action: AppActions) => {
  switch (action.type) {
    case ContextTypes.authToken:
      return {
        ...state,
        authToken: action.payload,
      };
    case ContextTypes.profileData:
      return {
        ...state,
        profileData: action.payload,
      };
    default:
      return state;
  }
};

export const updateAuthToken = ({ authToken }: { authToken: string }) => {
  return {
    type: ContextTypes.authToken,
    payload: authToken,
  };
};

export const updateProfileData = ({
  profileData,
}: {
  profileData: any | null;
}) => {
  return {
    type: ContextTypes.profileData,
    payload: profileData,
  };
};

