import apisauce, { ApiResponse } from "apisauce";
import { getAuthToken, returnToken, TokenType } from "./tokens";
import { authHeaders, defaultHeaders } from "./apiHeaders";
import Cookies from "js-cookie";
export interface ApiObjTypes {
  url: string;
  type: string;
  params: any;
  headers?: any;
  token?: TokenType;
  addedHeaders?: { [key: string]: string };
}

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api"
    : "https://xeniapi.xeniapps.co.uk/api";
// const baseUrl = 'http://localhost:8000/api';

export const api = apisauce.create({
  baseURL: baseUrl,
  timeout: 10000,
  withCredentials: true,
  headers: {
    "X-App-Name": "xeniapi",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  },
});

export const tokenApi = apisauce.create({
  baseURL: baseUrl,
  timeout: 10000,
});

api.addAsyncRequestTransform(() => async () => {
  await getAuthToken();
});

const callApi = async ({ apiObj }: { apiObj: ApiObjTypes }) => {
  const { url, params, type, token = "app", addedHeaders } = apiObj;
  const apiToken = Cookies.get(token + "Token");
  try {
    let response;
    switch (type) {
      case "get":
        response = await api.get(url, params, {
          headers: { ...authHeaders(apiToken), ...addedHeaders },
        });
        break;
      case "post":
        response = await api.post(url, params, {
          headers: { ...authHeaders(apiToken), ...addedHeaders },
        });
        break;
      case "patch":
        response = await api.patch(url, params, {
          headers: { ...authHeaders(apiToken), ...addedHeaders },
        });
        break;
      case "delete":
        response = await api.delete(url, params, {
          headers: { ...authHeaders(apiToken), ...addedHeaders },
        });
        break;
    }
    const { status, data } = response;
    return { status, data };
  } catch (error) {
    return {
      status: 500,
      data: undefined,
    };
  }
};

export const getCsrfCookie = async () => {
  const response: ApiResponse<any, any> = await tokenApi.get(
    "/sanctum/csrf-cookie",
    {},
    { headers: defaultHeaders }
  );
  const { status, data } = response;
  return { status, data };
};

// getCsrfCookie()

export const register = async ({ params }: { params: any }) => {
  const { username, name, email, password, confirmPassword } = params;
  const apiObj = {
    url: "/register",
    params: {
      username,
      name,
      email,
      password,
      password_confirmation: confirmPassword,
    },
    type: "post",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const login = async ({ params }: { params: any }) => {
  const apiObj = {
    url: "/login",
    params,
    type: "post",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const socialLogin = async ({ params }: { params: any }) => {
  const apiObj = {
    url: "/social-login",
    params,
    type: "post",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const logout = async () => {
  const apiObj = {
    url: "/logout",
    params: {},
    type: "post",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const refreshAppToken = async ({
  tokenType = "app",
}: {
  tokenType?: TokenType;
}) => {
  const apiToken = returnToken(tokenType);
  const response: ApiResponse<any, any> = await tokenApi.post(
    "/token/refresh",
    {},
    { headers: authHeaders(apiToken) }
  );
  const { status, data } = response;
  return { status, data };
};

export const getUserData = async () => {
  const apiObj = {
    url: "/profile",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getSettings = async () => {
  const apiObj = {
    url: "/settings",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const updateSettings = async ({ params }) => {
  const apiObj = {
    url: "/settings",
    params,
    type: "patch",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getSettlements = async () => {
  const apiObj = {
    url: "/settlements",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getSettlement = async ({ id }: { id: number }) => {
  const apiObj = {
    url: `/settlements/${id}`,
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const addSettlement = async ({ params }) => {
  const apiObj = {
    url: "/settlements",
    params,
    type: "post",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const updateSettlement = async ({ params, id }) => {
  const apiObj = {
    url: `/settlements/${id}`,
    params,
    type: "patch",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getRestaurants = async ({ id }: { id: number }) => {
  const apiObj = {
    url: `/restaurants/${id}`,
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getPubs = async ({ id }: { id: number }) => {
  const apiObj = {
    url: `/pubs/${id}`,
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getCafes = async ({ id }: { id: number }) => {
  const apiObj = {
    url: `/cafes/${id}`,
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const addPubs = async ({ params, settlementId }) => {
  const apiObj = {
    url: `/pubs/${settlementId}`,
    params,
    type: "post",
    addedHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await callApi({ apiObj });
  return response;
};

export const addRestaurants = async ({ params, settlementId }) => {
  const apiObj = {
    url: `/restaurants/${settlementId}`,
    params,
    type: "post",
    addedHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await callApi({ apiObj });
  return response;
};

export const addCafes = async ({ params, settlementId }) => {
  const apiObj = {
    url: `/cafes/${settlementId}`,
    params,
    type: "post",
    addedHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getSuggestions = async () => {
  const apiObj = {
    url: "/suggestions",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getAdminInfo = async () => {
  const apiObj = {
    url: "/admin-info",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const contactMe = async ({ params }: { params: any }) => {
  const apiObj = {
    url: "/contact-messages",
    params,
    type: "post",
    token: null,
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getContactMessages = async () => {
  const apiObj = {
    url: "/contact-messages",
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const getContactMessage = async ({ id }: { id: number }) => {
  const apiObj = {
    url: `/contact-messages/${id}`,
    params: {},
    type: "get",
  };
  const response = await callApi({ apiObj });
  return response;
};

export const updateContactMessage = async ({
  id,
  responded,
}: {
  id: number;
  responded: boolean;
}) => {
  const apiObj = {
    url: `/contact-messages/${id}`,
    params: {
      responded,
    },
    type: "patch",
  };
  const response = await callApi({ apiObj });
  return response;
};
