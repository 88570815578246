import StumblrLogo from "../assets/stumblr-logo.png";
import StumblrLaunch from "../assets/screenshots/stumblr_launch.png";
import StumblrScreenshot2 from "../assets/screenshots/Screenshot_20240828-194259.png";
import StumblrScreenshot3 from "../assets/screenshots/Screenshot_20240828-194303.png";
import StumblrScreenshot4 from "../assets/screenshots/Screenshot_20240828-194313.png";
import StumblrScreenshot5 from "../assets/screenshots/Screenshot_20240828-194323.png";
import StumblrScreenshot6 from "../assets/screenshots/Screenshot_20240828-194338.png";
import StumblrScreenshot7 from "../assets/screenshots/Screenshot_20240828-194347.png";
import StumblrScreenshot8 from "../assets/screenshots/Screenshot_20240828-194412.png";

import CaffeindLogo from "../assets/caffeind.png";
import CaffeindLaunch from "../assets/screenshots/caffeind_launch.png";

export const appData = {
  stumblr: {
    key: "stumblr",
    title: "Stumblr",
    logo: StumblrLogo,
    active: true,
    links: {
      appStore: "https://apps.apple.com/gb/app/stumblr/id1570342884",
      playStore:
        "https://play.google.com/store/apps/details?id=com.stumblr&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
    },
    description: `Stumblr is a pub crawl app that allows the user to filter their local area for pubs based on various features, be it dog-friendly, sunday roasts or pool tables, and tick them off as they go. 
    
    The user can create their own pub crawls or gain inspiration from others created by other users. Now available on the app store and the play store!`,
    screenshots: [
      StumblrLaunch,
      StumblrScreenshot2,
      StumblrScreenshot3,
      StumblrScreenshot4,
      StumblrScreenshot5,
      StumblrScreenshot6,
      StumblrScreenshot7,
      StumblrScreenshot8,
    ],
    type: "app",
  },
  tastd: {
    key: "tastd",
    title: "Tastd",
    logo: "",
    active: false,
    links: {
      appStore: "",
      playStore: "",
    },
    description: "",
    screenshots: [],
    type: "app",
  },
  caffeind: {
    key: "caffeind",
    title: "Caffeind",
    logo: CaffeindLogo,
    active: false,
    links: {
      appStore: "",
      playStore: "",
    },
    description: "",
    screenshots: [CaffeindLaunch],
    type: "app",
  },
  natalies: {
    key: "caffeind",
    title: "Caffeind",
    logo: CaffeindLogo,
    active: false,
    links: {
      appStore: "",
      playStore: "",
    },
    description: "",
    screenshots: [CaffeindLaunch],
    type: "app",
  },
};
