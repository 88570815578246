"use client"
import { useState } from 'react';
import { FormObjectType, getFormFieldsErrorsInitialState, getFormFieldsInitialState } from '../../utils/form';
import { convertApiErrors, validateField, validateForm } from '../../utils/validations';
import { register } from '../../services/api';
import { setToken } from '../../services/tokens';
import CustomButton from '../components/buttons/CustomButton';
import FormInput from '../components/form/Input';
import Xeniapps from '../../assets/logo-branches.png'

import styles from './styles/Register.module.css'

const fields = ['username', 'name', 'email', 'password', 'confirmPassword'];
const fieldTypes = {
    username: 'text',
    name: 'text',
    email: 'email',
    password: 'password',
    confirmPassword: 'password'
}
const formType = 'register'

export default function RegisterPage() {

    const [registering, setRegistering] = useState<boolean>(false);

    const [form, setForm] = useState<FormObjectType>(
        getFormFieldsInitialState({ fields }),
    );

    const [formErrors, setFormErrors] = useState<any>(
        getFormFieldsErrorsInitialState({ fields }),
    );

    const handleFieldChange = ({
        key,
        value,
    }: {
        key: string;
        value: string;
    }) => {
        setFormErrors(prevState => {
            const updatedState = {
                ...prevState,
                [key]: [],
                general: [],
            };
            return updatedState;
        });
        setForm(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    function handleValidateField(key: string): boolean {
        const errors = validateField({
            formType,
            key,
            value: form[key],
        });
        if (errors.length) {
            setFormErrors(prevState => ({
                ...prevState,
                [key]: errors,
            }));
            return true;
        }
        return false;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRegistering(true);

        const { errorsObject, formHasErrors } = validateForm({
            formType,
            form,
        });

        if (formHasErrors) {
            setFormErrors(errorsObject);
            setRegistering(false);
            return;
        }

        const { status, data } = await register({
            params: form,
        });

        if (status === 200) {
            if (data) {
                setToken({ type: 'app', token: data.appToken })
                setToken({ type: 'refresh', token: data.refreshToken })
                setRegistering(false);
                // router.push('/dashboard')
                return;
            }
        } else if (status !== 500) {
            const { errorsObj, showToast } = convertApiErrors({
                formErrors,
                apiErrors: data,
            });
            setFormErrors(errorsObj);
            if (showToast) {
                //   Toast.show({
                //     type: 'error',
                //     text1: i18n.t('common.error'),
                //     text2: errorsObj.general ?? '',
                //   });
            }
        }
        setRegistering(false);
    };

    return (
        <div className={styles.container}>
            <img
                className={styles.logo}
                src={Xeniapps}
                alt="xeniapps"
                height={120}
            />
            <h1 className={styles.heading}>Register</h1>
            <form onSubmit={handleSubmit}>
                {fields.map((field) =>
                    <FormInput
                        field={field}
                        type={fieldTypes[field]}
                        value={form[field]}
                        onBlur={() => handleValidateField(field)}
                        onChange={(e: any) => handleFieldChange({ key: field, value: e.target.value })}
                        errors={formErrors[field]}
                    />
                )}
                <CustomButton text={'Register'} isForm showSpinner={registering} />
            </form>
        </div>
    );
}