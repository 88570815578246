import { getSuggestions } from "../../../services/api";
import { useEffect, useState } from "react";

const useSuggestions = () => {

	const [fetchingSuggestions, setFetchingSuggestions] = useState<boolean>(false);

	const [suggestions, setSuggestions] = useState<Array<any>>([]);

	useEffect(() => {
		setFetchingSuggestions(true);
		const fetchSuggestions = async () => {
			const { status, data } = await getSuggestions();
			if (status === 200 && data) {
				setSuggestions(data);
			}
		}

		fetchSuggestions().then(() => setFetchingSuggestions(false)).catch(() => setFetchingSuggestions(false))
	}, [])


	return {
		fetchingSuggestions,
		suggestions,
	}
}

export default useSuggestions;