import useSuggestions from "./hooks/useSuggestions"

const Suggestions = () => {

	const { suggestions } = useSuggestions();

	return <div>
		{suggestions.length ? <div></div> : <div><p>No Suggestions so far</p></div>}
	</div>
}

export default Suggestions