import { getContactMessage } from "../../../services/api";
import { useEffect, useState } from "react";

const useContactMessage = ({ id }) => {
  const [fetchingMessage, setFetchingMessage] = useState<boolean>(false);
  const [messageDetails, setMessageDetails] = useState<{
    name: string;
    email: string;
    message: string;
    responded: boolean;
  }>(null);

  useEffect(() => {
    setFetchingMessage(true);
    const fetchSuggestions = async () => {
      const { status, data } = await getContactMessage({ id });
      if (status === 200 && data) {
        setMessageDetails(data);
      }
    };

    if (id) {
      fetchSuggestions()
        .then(() => setFetchingMessage(false))
        .catch(() => setFetchingMessage(false));
    }
  }, [id]);

  const onRespondedChange = (e) => {
    console.log(e);
  };

  return {
    fetchingMessage,
    messageDetails,
    onRespondedChange,
  };
};

export default useContactMessage;
