import { addCafes, addPubs, addRestaurants } from "../../../../../services/api";
import { useRef, useState } from "react"
import { useParams } from "react-router-dom";

const functions = {
    pubs: addPubs,
    cafes: addCafes,
    restaurants: addRestaurants
}

const useMultiple = () => {

    const { id, type } = useParams();

    const [submitting, setSubmitting] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSubmitting(true);

        const formData = new FormData();
        formData.append('file', selectedFile);

        console.log('formData: ', formData.get('file'))

        const { status } = await functions[type]({ params: formData, settlementId: id })

        console.log('status: ', status)
        // console.log('data: ', data)

        if (status === 200) {
            // console.log(data)
            // router.push('/businesses')
        }

        setSubmitting(false);
    };

    return {
        handleFileChange,
        selectedFile,
        fileInputRef,
        submitting,
        handleSubmit
    }
}

export default useMultiple