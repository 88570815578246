import { getCafes, getPubs, getRestaurants } from "../../../services/api";
import { useEffect, useState } from "react";


const functions = {
    pubs: {
        fetch: getPubs,
        // destroy: 
    },
    cafes: {
        fetch: getCafes,
        // destroy: 
    },
    restaurants: {
        fetch: getRestaurants,
        // destroy: 
    }
}

const useBusinesses = ({ settlementId, type }: { settlementId: string; type: string }) => {

    const [businesses, setBusinesses] = useState<any[]>([])
    const [fetchingBusinesses, setFetchingBusinesses] = useState<boolean>(false)

    useEffect(() => {
        const fetchBusinesses = async () => {
            setFetchingBusinesses(true);
            const { status, data } = await functions[type].fetch({ id: settlementId });
            if (status === 200) {
                setBusinesses(data)
            }
        }
        if (type && settlementId) {
            fetchBusinesses().then(() => setFetchingBusinesses(false)).catch(() => setFetchingBusinesses(false));
        }
    }, [type, settlementId])

    return {
        businesses,
        fetchingBusinesses,
    }

}

export default useBusinesses;