import { Spinner } from "react-activity"
import useMultiple from "./hooks/useAddMultiple"
import Content from "../../../../app/components/layout/content/Content"
import Header from "../../../../app/components/layout/header/Header"
import Sidebar from "../../../../app/components/layout/sidebar/Sidebar"
import styles from './styles/AddMultiple.module.css'
import CustomButton from "../../../../app/components/buttons/CustomButton"

export default function AddMultiple() {

    const {
        handleFileChange,
        selectedFile,
        fileInputRef,
        submitting,
        handleSubmit
    } = useMultiple()

    return (
        <div>
            <Header />
            <Sidebar />
            <Content>
                <form className={styles.form}>
                    <input type="file" name="file" accept="application/json" ref={fileInputRef} onChange={handleFileChange} />
                    {submitting ?
                        <Spinner color="#000000" />
                        :
                        <CustomButton text={'Upload File'} onClick={handleSubmit} disabled={!selectedFile} />
                    }
                </form>
            </Content>
        </div>)
}