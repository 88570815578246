
import { addSeconds, sleep } from '../utils/appUtils';
import Cookies from 'js-cookie';
import { refreshAppToken } from './api';

export type TokenType = 'app' | 'refresh'

let tokens: { [key in TokenType]: string | null } = {
  app: null,
  refresh: null,
}

let authTokenExpiry = addSeconds(new Date(), 60 * 60);
let authTokenInProgress = false;

export const setToken = ({ type, token }: { type: TokenType, token: string | null }) => {
  Cookies.set(type + 'Token', token);
  tokens[type] = token;
  if (type === 'app') {
    authTokenExpiry = addSeconds(new Date(), 60 * 60) // Expiry of 60 minutes;
  }
  return true
};

export const resetToken = ({ type }: { type: TokenType }) => {
  tokens[type] = null;
};

export const clearTokens = async () => {
  await Cookies.remove('appToken');
  await Cookies.remove('refreshToken');
  tokens = {
    app: null,
    refresh: null,
  }

  return true
};

export const refreshAuthTokenUsingRefreshToken = async () => {
  const { status, data } = await refreshAppToken({ tokenType: 'refresh' });
  if (status === 200) {
    setToken({ type: 'app', token: data.appToken })
    setToken({ type: 'refresh', token: data.refreshToken })
    return true
  }

  await clearTokens();
  return false
};

export const refreshAuthToken = async () => {
  const refreshToken = await Cookies.get('refreshToken');
  const { status, data } = await refreshAppToken({ tokenType: 'app' });
  if (status === 200) {
    setToken({ type: 'app', token: data.appToken })
    setToken({ type: 'refresh', token: data.refreshToken })
    return true
  } else if (refreshToken && status === 401) {
    return await refreshAuthTokenUsingRefreshToken();
  }
  await clearTokens();
  return false
};

export const getAuthToken = async () => {
  while (authTokenInProgress) {
    await sleep(300);
  }
  authTokenInProgress = true;

  if (new Date(authTokenExpiry) < new Date(addSeconds(new Date(), 60 * 10))) {
    return await refreshAuthToken();
  }
  authTokenInProgress = false;
  return tokens['app'];
};
export const returnToken = (type: TokenType) => {
  return tokens[type];
};
