import { stackIcons } from "../../utils/icons";
import useAbout from "./hooks/useAbout";
import styles from "./styles/About.module.css";

const About = () => {
  const { developers, activeDeveloper, setActiveDeveloper } = useAbout();

  const { name, description, work, links } = activeDeveloper;

  return (
    <div className={styles.about}>
      <p className={styles.title}>About</p>
      <div className={styles.developers}>
        {developers.map((developer) => (
          <div
            onClick={() => setActiveDeveloper(developer)}
            className={styles.developerIcon}
          >
            <img
              src={developer.icon}
              className={styles.developerIconImage}
              alt={developer.name}
            />
          </div>
        ))}
      </div>
      <div className={styles.developerDetails}>
        {/* <div className={styles.username}>{username}</div> */}
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>
          <div className={styles.descriptionText}>{description}</div>
          <div className={styles.descriptionText}>{work}</div>
        </div>

        {links && links.length && (
          <div className={styles.icons}>
            {links.map((tech) => {
              const TechIcon = stackIcons[tech.icon].icon;
              if (TechIcon === "") return null;
              return (
                <a href={tech.link} className={styles.icon}>
                  <TechIcon
                    className={styles.techIcon}
                    height={30}
                    width={30}
                  />
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default About;
