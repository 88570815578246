import FormInput from "../components/form/Input";
import useContact from "./hooks/useContact";
import CustomButton from "../components/buttons/CustomButton";
import styles from "./styles/Contact.module.css";

const fieldTypes = {
  name: "text",
  email: "email",
  message: "textarea",
};

const Contact = () => {
  const {
    fields,
    form,
    formErrors,
    contacting,
    handleFieldChange,
    handleValidateField,
    handleSubmit,
    hasSentMessage,
    contactLinks,
  } = useContact();

  return (
    <div className={styles.contactForm}>
      <p className={styles.title}>Contact</p>
      <p className={styles.contactMessage}>
        {`Please fill out the form below with
        your details and a brief description of your project needs. 
        
        I’ll get back to you soon to discuss how we can make it happen.`}
      </p>
      {contactLinks.map((link) => {
        const LinkIcon = link.icon;
        return (
          <div className={styles.contactLink} onClick={link.onClick}>
            <LinkIcon className={styles.contactLinkIcon} />
            <p className={styles.contactLinkText}>{link.string}</p>
          </div>
        );
      })}
      {hasSentMessage ? (
        <p className={styles.sentMessage}>
          Thanks for your message, I will get back to you as soon as possible
        </p>
      ) : (
        <form onSubmit={handleSubmit} className={styles.form}>
          {fields.map((field) => (
            <div className={styles.formInput}>
              <FormInput
                field={field}
                type={fieldTypes[field]}
                value={form[field]}
                onBlur={() => handleValidateField(field)}
                onChange={(e: any) =>
                  handleFieldChange({ key: field, value: e.target.value })
                }
                errors={formErrors[field]}
              />
            </div>
          ))}
          <CustomButton
            type="secondary"
            text={"Submit"}
            isForm
            disabled={contacting}
            showSpinner={contacting}
            showChevron={true}
            containerStyles={styles.buttonContainer}
          />
        </form>
      )}
    </div>
  );
};

export default Contact;
