import CustomButton from '../components/buttons/CustomButton';
import useLogin from './hooks/useLogin';
import styles from './styles/Login.module.css';
import Xeniapps from '../../assets/logo-branches.png'
import FormInput from '../components/form/Input';


const fieldTypes = {
  email: 'email',
  password: 'password',
}

export default function LoginPage() {

  const {
    fields,
    form,
    formErrors,
    loggingIn,
    handleFieldChange,
    handleValidateField,
    handleSubmit,
  } = useLogin()


  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src={Xeniapps}
        alt="xeniapps"
        height={120}
      />
      <h1 className={styles.heading}>Login</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        {fields.map((field) =>
          <FormInput
            field={field}
            type={fieldTypes[field]}
            value={form[field]}
            onBlur={() => handleValidateField(field)}
            onChange={(e: any) => handleFieldChange({ key: field, value: e.target.value })}
            errors={formErrors[field]}
          />
        )}
        <CustomButton
          text={'Login'}
          isForm
          disabled={loggingIn}
          showSpinner={loggingIn}
        />
      </form>
    </div>
  );
}