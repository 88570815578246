import useSidebar from './hooks/useSidebar'
import styles from './styles/Sidebar.module.css'

export default function Sidebar() {

    const { menuItems } = useSidebar()

    return (<nav className={styles.sidebar} id="sidebar">
        <ul className={styles.menuItems}>
            {menuItems.map(menuItem => <li className={styles.menuItem} onClick={menuItem.onClick}>
                {menuItem.label}
            </li>)}
        </ul>
    </nav>)
}