import { useNavigate } from 'react-router-dom';
import styles from './styles/Tabs.module.css'

type Props = {
    tabs: { label: string; value: string; }[];
    activeTab: string;
    hrefPrefix?: string;
    isFrontend?: boolean;
}

export default function Tabs(props: Props) {

    const { tabs, activeTab, hrefPrefix = '', isFrontend } = props;

    const navigate = useNavigate();

    const navigateTo = ({ tabValue }: { tabValue: string }) => {
        if (isFrontend) {
            navigate(`/${tabValue}`)
        } else {
            navigate(`/${hrefPrefix}/${tabValue}`)
        }
    }

    return (
        <div className={styles.container}>
            {tabs.map((tab) => {
                const isActive = activeTab === tab.value
                return <div className={styles.buttonContainer}>
                    <button className={`${styles.tab} ${isActive ? styles.active : ''}`} disabled={isActive} onClick={() => navigateTo({ tabValue: tab.value })}>
                        {tab.label}
                    </button>
                </div>

            })}
        </div >
    )
}