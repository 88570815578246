import styles from "./styles/Homepage.module.css";
import LogoText from "../../assets/logo-text.png";
import LogoNoSign from "../../assets/logo-no-sign.png";
import LandingImage from "../../assets/landing-image.png";
import AppPage from "../app-page/AppPage";
import { useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CustomButton from "../components/buttons/CustomButton";
import Contact from "./Contact";
import About from "./About";

const Homepage = () => {
  const height = window.outerHeight;

  const pageScrollRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const onPressHome = () => {
    pageScrollRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onPressAbout = () => {
    pageScrollRef.current.scrollTo({
      top: aboutRef.current.offsetTop - 60,
      behavior: "smooth",
    });
  };

  const onPressContact = () => {
    pageScrollRef.current.scrollTo({
      top: contactRef.current.offsetTop - 20,
      behavior: "smooth",
    });
  };

  const viewportHeight = {
    maxHeight: `${height - 70}px`,
    marginBottom: "150px",
    minHeight: "800px",
  };

  return (
    <div className={`${styles.pageContainer}`} ref={pageScrollRef}>
      <div className={styles.header}>
        <div className={styles.logoContainer} onClick={onPressHome}>
          <img
            className={styles.logo}
            src={LogoNoSign}
            alt="Xeniapps"
            height={50}
          />
          <img className={styles.logoText} src={LogoText} alt="Xeniapps" />
        </div>
        <div className={styles.buttonContainer}>
          <CustomButton text="About" onClick={onPressAbout} type="link" />
          <CustomButton text="Contact" onClick={onPressContact} type="link" />
        </div>
      </div>
      <div className={styles.landingPage} style={viewportHeight}>
        <img
          className={styles.landingImage}
          src={LandingImage}
          alt="Landing"
          height={222}
        />
        <div className={styles.landingTextContainer}>
          <p className={styles.landingText}>
            Freelance full stack developer specialising in React Native.
          </p>
          <CustomButton
            text="Work With Me"
            onClick={onPressContact}
            type="secondary"
            showChevron
          />
        </div>
      </div>
      <div className={styles.work} style={viewportHeight}>
        <AppPage />
      </div>
      <div className={styles.about} ref={aboutRef} style={viewportHeight}>
        <About />
      </div>
      <div ref={contactRef} style={viewportHeight}>
        <Contact />
      </div>
      <div className={styles.footer}>
        <p className={styles.footerText}>&copy; Xeniapps 2024</p>
        <img
          className={styles.footerLogo}
          src={LogoNoSign}
          alt="Logo"
          height={25}
        />
        <p className={styles.footerText}>All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Homepage;
