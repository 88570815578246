import React, {createContext, useReducer, Dispatch, useMemo} from 'react';
import {appInitialState} from './initialStates/AppInitialState';
import {AppActions, appReducer} from './reducers/appReducer';
import {AppInitialStateType} from './types/AppInitialStateType';

interface AppProviderProps {
  children: React.ReactNode | undefined;
}

const AppContext = createContext<{
  state: AppInitialStateType;
  dispatch: Dispatch<AppActions>;
}>({
  state: appInitialState,
  dispatch: () => null,
});

function AppProvider({children}: AppProviderProps) {
  const [state, dispatch] = useReducer(appReducer, appInitialState);

  const providerValue = useMemo(() => {
    return {state, dispatch};
  }, [state, dispatch]);

  return (
    <AppContext.Provider value={providerValue}>{children}</AppContext.Provider>
  );
}

export {AppProvider, AppContext};
