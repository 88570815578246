import { AppType } from "@/types/app";

export type FormObjectType = {
    [key: string]: string | null;
};

export type FormErrorObjectType = {
    [key: string]: Array<string>;
    general?: Array<string>;
};

export const getFormFieldsInitialState = ({
    fields,
}: {
    fields: Array<string>;
}) => {
    return fields.reduce(
        (obj: FormObjectType, field) => ({
            ...obj,
            [field]: '',
        }),
        {},
    );
};

export const getFormFieldsErrorsInitialState = ({
    fields,
}: {
    fields: Array<string>;
}) => {
    return fields.reduce(
        (obj: FormErrorObjectType, field) => ({ ...obj, [field]: [] }),
        { general: [] },
    );
};

export const returnAppActiveStatus = ({ app, data }: { app: AppType, data: any }) => {
    if ((data[`${app}_is_active`])) {
        return 'isActive'
    } else if (data[`${app}_coming_soon`]) {
        return 'comingSoon'
    } else {
        return 'inactive'
    }
}