import useSettlements from "./hooks/useSettlements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBeer, faBurger, faCirclePlus, faCoffee, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from "react-activity";
import styles from './styles/Settlements.module.css'


export default function SettlementsPage() {

    const { settlements, fetchingSettlements, navigate } = useSettlements()

    return (
        <>
            {fetchingSettlements ?
                <Spinner /> :
                <div className={styles.settlementsContainer}>
                    <h2 className={styles.settlementsTitle}>
                        Settlements
                    </h2>
                    <div className={styles.settlements}>
                        {settlements.map((settlement) => (
                            <div key={settlement.id} className={styles.settlement}>
                                <div className={styles.settlementLeft}>
                                    <p className={styles.settlementId}>{settlement.id}</p>
                                    <p onClick={() => navigate(`/businesses/${settlement.id}/pubs`)} className={styles.settlementName}>
                                        {settlement.name}
                                    </p>
                                </div>
                                <div className={styles.businessTypes}>
                                    <FontAwesomeIcon icon={faBeer} className={styles.businessTypeIcon} onClick={() => navigate(`/businesses/${settlement.id}/pubs`)} />
                                    <FontAwesomeIcon icon={faCoffee} className={styles.businessTypeIcon} onClick={() => navigate(`/businesses/${settlement.id}/cafes`)} />
                                    <FontAwesomeIcon icon={faBurger} className={styles.businessTypeIcon} onClick={() => navigate(`/businesses/${settlement.id}/restaurants`)} />
                                </div>
                                <div className={styles.settlementFunctions}>
                                    <div onClick={() => navigate(`/settlements/${settlement.id}`)} className={styles.edit}>
                                        <FontAwesomeIcon icon={faPencil} />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div onClick={() => navigate("/settlements/add")} className={styles.addSettlement}>
                            Add Settlement
                            <div className="towns__add-town-icon ml-2 text-black">
                                <FontAwesomeIcon icon={faCirclePlus} />
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
}