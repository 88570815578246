import { FaEnvelopeOpen, FaMobile } from "react-icons/fa";
import { contactMe } from "../../../services/api";
import {
  FormObjectType,
  getFormFieldsErrorsInitialState,
  getFormFieldsInitialState,
} from "../../../utils/form";
import {
  convertApiErrors,
  validateField,
  validateForm,
} from "../../../utils/validations";
import { useState } from "react";

const fields = ["name", "email", "message"];

const useContact = () => {
  const [contacting, setContacting] = useState<boolean>(false);
  const [hasSentMessage, setHasSentMessage] = useState<boolean>(false);

  const [form, setForm] = useState<FormObjectType>(
    getFormFieldsInitialState({ fields })
  );

  const [formErrors, setFormErrors] = useState<any>(
    getFormFieldsErrorsInitialState({ fields })
  );

  const handleFieldChange = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    setFormErrors((prevState) => {
      const updatedState = {
        ...prevState,
        [key]: [],
        general: [],
      };
      return updatedState;
    });
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  function handleValidateField(key: string): boolean {
    const errors = validateField({
      formType: "contact",
      key,
      value: form[key],
    });
    if (errors.length) {
      setFormErrors((prevState) => ({
        ...prevState,
        [key]: errors,
      }));
      return true;
    }
    return false;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setContacting(true);

    const { errorsObject, formHasErrors } = validateForm({
      formType: "contact",
      form,
    });

    if (formHasErrors) {
      setFormErrors(errorsObject);
      setContacting(false);
      return;
    }

    const { status, data } = await contactMe({
      params: form,
    });

    if (status === 200) {
      setHasSentMessage(true);
    } else if (status !== 500) {
      const { errorsObj } = convertApiErrors({
        formErrors,
        apiErrors: data,
      });
      setFormErrors(errorsObj);
    }
    setContacting(false);
  };

  const email = "marcozciuffini@gmail.com";
  const phone = "+447410900084";

  const clickEmailOrPhone = (emailOrPhone: "email" | "phone") => {
    if (emailOrPhone === "email") {
      window.location.href = `mailto:${email}`;
    } else if (emailOrPhone === "phone") {
      window.location.href = `tel:${phone}`;
    }
  };

  const contactLinks = [
    {
      type: "email",
      string: email,
      icon: FaEnvelopeOpen,
      onClick: () => clickEmailOrPhone("email"),
    },
    {
      type: "phone",
      string: "+44 (0) 7410 900084",
      icon: FaMobile,
      onClick: () => clickEmailOrPhone("phone"),
    },
  ];

  return {
    fields,
    form,
    formErrors,
    contacting,
    handleFieldChange,
    handleValidateField,
    handleSubmit,
    hasSentMessage,
    contactLinks,
  };
};

export default useContact;
