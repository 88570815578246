import CustomButton from "../../buttons/CustomButton";
import useHeader from "./hooks/useHeader";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles/Header.module.css";

export default function Header() {
  const { profileData, handlePressXeniapps, handleLogout } = useHeader();

  return (
    <header className={styles.header}>
      <div className={styles.headerLeft}>
        <h1 className={styles.headerTitle} onClick={handlePressXeniapps}>
          Xeniapps
        </h1>
      </div>
      <div className={styles.headerRight}>
        <p className={styles.name}>{profileData?.name}</p>
        <div
          id="login-dropdown-menu"
          className="absolute top-full w-[calc(100%+4px)] p-4 bg-white rounded-b-md border shadow-md mt-1 left-[-4px] hidden"
        >
          <CustomButton text={"Logout"} onClick={handleLogout} />
        </div>
      </div>
    </header>
  );
}
