import { PacmanLoader } from "react-spinners";
import useContactMessage from "./hooks/useContactMessage";
import styles from "./styles/ContactMessage.module.css";
import { useParams } from "react-router-dom";

const ContactMessage = () => {
  const { id } = useParams();

  const { fetchingMessage, messageDetails, onRespondedChange } =
    useContactMessage({ id });

  if (fetchingMessage) {
    return (
      <div className={styles.loadingContainer}>
        <PacmanLoader />
      </div>
    );
  }

  if (!messageDetails) {
    return (
      <div>
        <p>Message not found</p>
      </div>
    );
  }

  return (
    <div className={styles.contactMessage}>
      <p className={styles.name}>{messageDetails.name}</p>
      <p className={styles.email}>{messageDetails.email}</p>
      <p className={styles.message}>{messageDetails.message}</p>
      <div className={styles.responded}>
        <input
          type="radio"
          id="responded"
          name="responded"
          value="responded"
          checked={messageDetails.responded}
          onChange={onRespondedChange}
        />
      </div>
    </div>
  );
};

export default ContactMessage;
