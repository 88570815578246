import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { updateProfileData } from "../../../context/reducers/appReducer";
import { login } from "../../../services/api";
import { setToken } from "../../../services/tokens";
import { FormObjectType, getFormFieldsErrorsInitialState, getFormFieldsInitialState } from "../../../utils/form";
import { convertApiErrors, validateField, validateForm } from "../../../utils/validations";
import { useContext, useState } from "react";

const fields = ['email', 'password'];

const formType = 'login'

const useLogin = () => {
    const { dispatch } = useContext(AppContext)

    const navigate = useNavigate();

    const [loggingIn, setLoggingIn] = useState<boolean>(false);

    const [form, setForm] = useState<FormObjectType>(
        getFormFieldsInitialState({ fields }),
    );

    const [formErrors, setFormErrors] = useState<any>(
        getFormFieldsErrorsInitialState({ fields }),
    );

    const handleFieldChange = ({
        key,
        value,
    }: {
        key: string;
        value: string;
    }) => {
        setFormErrors(prevState => {
            const updatedState = {
                ...prevState,
                [key]: [],
                general: [],
            };
            return updatedState;
        });
        setForm(prevState => ({
            ...prevState,
            [key]: value,
        }));
    }

    function handleValidateField(key: string): boolean {
        const errors = validateField({
            formType,
            key,
            value: form[key],
        });
        if (errors.length) {
            setFormErrors(prevState => ({
                ...prevState,
                [key]: errors,
            }));
            return true;
        }
        return false;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoggingIn(true);

        const { errorsObject, formHasErrors } = validateForm({
            formType,
            form,
        });

        if (formHasErrors) {
            setFormErrors(errorsObject);
            setLoggingIn(false);
            return;
        }

        const { status, data } = await login({
            params: form,
        });

        if (status === 200) {
            if (data) {
                console.log('data:', data)
                setToken({ type: 'app', token: data.appToken })
                setToken({ type: 'refresh', token: data.refreshToken })
                dispatch(updateProfileData({ profileData: data.user }))
                setLoggingIn(false);
                navigate("/dashboard");
                console.log('LOGGED IN')
                // router.push('/dashboard')
                return;
            }
        } else if (status !== 500) {
            const { errorsObj, showToast } = convertApiErrors({
                formErrors,
                apiErrors: data,
            });
            setFormErrors(errorsObj);
            if (showToast) {
                //   Toast.show({
                //     type: 'error',
                //     text1: i18n.t('common.error'),
                //     text2: errorsObj.general ?? '',
                //   });
            }
        }
        setLoggingIn(false);
    };

    return {
        fields,
        form,
        formErrors,
        loggingIn,
        handleFieldChange,
        handleValidateField,
        handleSubmit,
    }
}

export default useLogin