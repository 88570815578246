import useBusinesses from "./hooks/useBusinesses"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPencil, faPhone, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-activity";
import styles from './styles/Businesses.module.css'
import { Link } from "react-router-dom";

export default function Businesses({ settlementId, type }: { settlementId: string; type: string }) {

    const { businesses, fetchingBusinesses } = useBusinesses({ settlementId, type });

    return <div>
        {fetchingBusinesses ? <Spinner /> : <div className="container mx-auto p-5">

            <div className={styles.businessesContainer}>
                {/* {businessName && (
                    <div className="businesses__deleted-business">
                        <p className="text-green-500">{businessName} deleted successfully!</p>
                    </div>
                )} */}
                <h2 className={styles.businessesTitle}>{type}</h2>
                <div className={styles.business}>
                    <div className={styles.businessLeft}>
                        <p className={styles.businessId}>ID</p>
                        <p className={styles.businessName}>Name</p>
                    </div>
                    <div />
                </div>
                <div className={styles.businesses}>
                    {businesses.map((business, index) => (
                        <div key={business.id} className={styles.business}>
                            <div className={styles.businessLeft}>
                                <p className={styles.businessId}>{business.id}</p>
                                <p className={styles.businessName}>{business.name}</p>
                                {/* Phone */}
                                {business.contact_phone || business.phone_number ? (
                                    // <Link className="businesses__business-contact" href={`tel:${business.contact_phone ?? business.phone_number}`}>
                                    <FontAwesomeIcon icon={faPhone} className={styles.businessIcon} />
                                    // </Link>
                                ) : null}
                                {/* Email */}
                                {business.contact_email ? (
                                    // <Link className="businesses__business-contact" href={`mailto:${business.contact_email}`}>
                                    <FontAwesomeIcon icon={faEnvelope} className={styles.businessIcon} />
                                    // </Link>
                                ) : null}
                            </div>
                            <div className={styles.businessRight}>
                                {/* <Link href={`/single-business/${business.id}/${index}`} className="businesses__business-link"> */}
                                <FontAwesomeIcon icon={faPencil} />
                                {/* </Link> */}
                                {/* Replace with your JSX deletion logic */}
                            </div>
                        </div>
                    ))}


                    {/* Add Business Links */}
                    {/* <Link href={`/businesses/${settlementId}/add/${type}`} className="businesses__add-business-link flex items-center justify-center gap-2 py-2 border border-gray-300 hover:bg-gray-100"> */}
                    <div>
                        <Link to={`add-business`}>
                            Add Business
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </Link>
                        {/* </Link> */}
                        {/* <Link href={`/businesses/${settlementId}/add-multiple/${type}`} className="businesses__add-business-link flex items-center justify-center gap-2 py-2 border border-gray-300 hover:bg-gray-100"> */}
                        <Link to={`add-json`}>
                            Add Json
                            <FontAwesomeIcon icon={faPlusCircle} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        }
    </div >
}