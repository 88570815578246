import { useNavigate } from "react-router-dom";

const useSidebar = () => {

	const navigate = useNavigate()

	const menuItems = [
		{ label: 'Settlements', onClick: () => navigate('/settlements') },
		{ label: 'Settings', onClick: () => navigate('/settings') },
		{ label: 'Suggestions', onClick: () => navigate('/suggestions') },
	];

	return {
		menuItems,
	}
}
export default useSidebar
