import { Navigate, Outlet } from "react-router-dom";
import Header from './components/layout/header/Header';
import Sidebar from './components/layout/sidebar/Sidebar';
import Content from './components/layout/content/Content';
import Cookies from 'js-cookie';

const ProtectedRoutes = () => {

    const appToken = Cookies.get('appToken');

    return appToken ?
        <div>
            <Header />
            <Sidebar />
            <Content>
                <Outlet />
            </Content>
        </div>
        : <Navigate to="/login" replace />;
};

export default ProtectedRoutes;