import { useState } from "react";
import { appData } from "../../../data/app";

const useAppPage = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const [activeApp, setActiveApp] = useState("stumblr");

  const activeAppData = appData[activeApp];

  const onChangeSlide = (swiper) => {
    const { activeIndex } = swiper;
    setCurrentSlide(activeIndex + 1);
  };

  const onChangeProject = (option) => {
    setActiveApp(option.value);
    setCurrentSlide(1);
  };

  return {
    activeAppData,
    currentSlide,
    onChangeSlide,
    onChangeProject,
  };
};

export default useAppPage;
