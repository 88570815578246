import Tabs from "../components/layout/tabs/Tabs";
import Businesses from "./Businesses"
import Header from "../components/layout/header/Header";
import Sidebar from "../components/layout/sidebar/Sidebar";
import Content from "../components/layout/content/Content";
import { useParams } from "react-router-dom";

const tabs = [{ label: 'Pubs', value: 'pubs' }, { label: 'Restaurants', value: 'restaurants' }, { label: 'Cafes', value: 'cafes' }]

const BusinessesPage = () => {

	const { id, type } = useParams();

	return <div>
		<Header />
		<Sidebar />
		<Content>
			<Tabs tabs={tabs} activeTab={type} hrefPrefix={`businesses/${id}`} />
			<Businesses settlementId={id} type={type} />
		</Content>
	</div>
}

export default BusinessesPage;