import { useRouteError } from "react-router-dom";
import Xeniapps from '../../assets/logo-branches.png'
import styles from './styles/ErrorPage.module.css'

export default function ErrorPage() {
    const error: any = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <div className={styles.logoContainer}>
                <img
                    className={styles.logo}
                    src={Xeniapps}
                    alt="xeniapps"
                    height={222}
                />
            </div>
            <h1>404</h1>
            <p className={styles.errorMessage}>Sorry, an unexpected error has occurred.</p>
            <p className={styles.errorMessage}>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}