// import { i18n } from '@/i18n/i18n';
import validator from 'validator';
import { FormErrorObjectType, FormObjectType } from './form';

export type ValidationsFunctionType = {
  [key: string]: {
    [key: string]: Array<
      ({
        value,
        key,
        secondValue,
      }: {
        value: string;
        key: string;
        secondValue: string;
      }) => string | false
    >;
  };
};
type FormType = 'login' | 'register' | 'settlement' | 'user' | 'contact'

export const validationFunctions = {
  required: ({ value, key }: { value: string; key: string }) => {
    if (!value || value === '') {
      return 'Field required';
    }
    return null;
  },
  email: ({ value, key }: { value: string; key: string }) => {
    if (!value || !validator.isEmail(value.trim())) {
      return 'Please provide a valid email'
    }
    return null;
  },
  stringLength:
    ({ minLength = 0, maxLength = 0 }) =>
      ({ value, key }: { value: string; key: string }) => {
        if (
          value &&
          !validator.isLength(value, {
            min: minLength,
            ...(maxLength && { max: maxLength }),
          })
        ) {
          return 'does not meet length';
        }
        return null;
      },
  alphanumeric: ({ value, key }: { value: string; key: string }) => {
    if (value && !validator.isAlphanumeric(value.replace(/\s/g, ''))) {
      return 'needs to be alphanumeric';
    }
    return null;
  },
  numeric: ({ value, key }: { value: string; key: string }) => {
    if (value && !validator.isNumeric(value.replace(/\s/g, ''))) {
      return 'needs to be numeric';
    }
    return null;
  },
  mismatch: ({
    value,
    key,
    secondValue,
  }: {
    value: string;
    key: string;
    secondValue: string;
  }) => {
    if (value !== secondValue) {
      return 'Values do not match'
    }
    return null;
  },
};

const { required, email, alphanumeric, numeric, mismatch } =
  validationFunctions;

export const validationRules: ValidationsFunctionType = {
  login: {
    email: [email],
    password: [required],
  },
  register: {
    username: [required, alphanumeric],
    name: [required, alphanumeric],
    email: [email],
    password: [required],
    confirmPassword: [required, mismatch],
  },
  settlement: {
    name: [required, alphanumeric],
    latitude: [required, numeric],
    longitude: [required, numeric],
    latDelta: [required, numeric],
    longDelta: [required, numeric],
  },
  contact: {
    name: [required, alphanumeric],
    email: [email],
    message: [required]
  }
};

export const validateField = ({
  formType = 'login',
  key,
  value,
  extraInfo,
}: {
  formType?: FormType
  key: string;
  value: string | null;
  extraInfo?: { secondValue?: string | null };
}) => {
  const secondValue = extraInfo?.secondValue ?? undefined;
  const validateFunctions = validationRules[formType][key];
  const errors = validateFunctions
    .map(
      (validate: (arg0: any) => string) =>
        validate({ key, value, secondValue }) ?? false,
    )
    .filter((error: string | false) => error);
  return errors;
};

export const validateForm = ({
  formType = 'login',
  form,
}: {
  formType?: FormType;
  form: FormObjectType;
}) => {
  let noOfErrors = 0;
  const errorsObject: any = Object.keys(form).reduce(
    (obj: FormErrorObjectType, formKey: string) => {
      if (formType === 'user' && formKey === 'phoneNumber') {
        return obj;
      }
      const extraInfo =
        formKey === 'confirmPassword' ? { secondValue: form.password } : {};

      const errors = validateField({
        formType,
        key: formKey,
        value: form[formKey],
        extraInfo,
      });
      if (errors.length) {
        noOfErrors++;
      }
      return {
        ...obj,
        [formKey]: errors,
      };
    },
    { general: [] },
  );

  return {
    errorsObject,
    formHasErrors: noOfErrors > 0,
  };
};

const formKeys: FormObjectType = {
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  password: 'newPassword',
  password_confirmation: 'confirmPassword',
};

export const convertApiErrors = ({
  formErrors,
  apiErrors,
}: {
  formErrors: FormErrorObjectType;
  apiErrors: FormErrorObjectType;
}) => {
  let noOfErrors = 0;
  if (typeof apiErrors === 'string') {
    return {
      errorsObj: { general: [apiErrors] },
      showToast: noOfErrors === 0,
    };
  } else if (!apiErrors) {
    return {
      errorsObj: { general: ['Something went wrong, please try again later.'] },
      showToast: true,
    };
  }

  const errorObj: FormErrorObjectType = Object.keys(apiErrors).reduce(
    (obj: FormErrorObjectType, key: string) => {
      const formErrorsKey: string = formKeys[key] ?? 'general';
      const error: string[] | null =
        apiErrors[key] ?? formErrors[formErrorsKey] ?? null;

      if (error !== null && formErrorsKey !== 'general') {
        noOfErrors++;
      }

      return {
        ...obj,
        [formErrorsKey]: error,
      };
    },
    formErrors,
  );

  return {
    errorsObj: errorObj,
    showToast: noOfErrors === 0,
  };
};
