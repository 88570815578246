import { AppContext } from "../../../context/AppContext";
import { getContactMessages } from "../../../services/api";
import { useContext, useEffect, useState } from "react";

const useDashboard = () => {
  const {
    state: { profileData },
  } = useContext(AppContext);

  const [contactMessages, setContactMessages] = useState([]);
  const [fetchingContactMessages, setFetchingContactMessages] = useState(true);

  console.log("contactMessages: ", contactMessages);

  useEffect(() => {
    const fetchContactMessages = async () => {
      setFetchingContactMessages(false);
      const { status, data } = await getContactMessages();
      console.log("contact messages status: ", status);
      console.log("contact messages data: ", data);
      setContactMessages(data ?? []);
      setFetchingContactMessages(false);
    };
    fetchContactMessages();
  }, []);

  return {
    profileData,
    contactMessages,
    fetchingContactMessages,
  };
};

export default useDashboard;
