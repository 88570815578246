import React from "react";
import styles from "./styles/Input.module.css";

type Props = {
  field?: string;
  type?: string;
  value: string;
  onBlur?: () => void;
  onChange: (e: any) => void;
  errors: string[];
  disabled?: boolean;
};

const FormInput = (props: Props) => {
  const { field, type, value, onBlur, onChange, errors, disabled } = props;

  return (
    <div className={styles.field} key={field}>
      <div className={styles.labelContainer}>
        <label htmlFor={field} className={styles.label}>
          {field}
        </label>
        {errors?.length > 0 && (
          <div className={styles.errors}>
            {errors.map((error) => (
              <p key={error} className={styles.error}>
                {error}
              </p>
            ))}
          </div>
        )}
      </div>
      {type === "textarea" ? (
        <textarea
          className={styles.textArea}
          id={field}
          name={field}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
          rows={8}
        />
      ) : (
        <input
          className={styles.input}
          type={type}
          id={field}
          name={field}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default FormInput;
