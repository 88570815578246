export const defaultHeaders = {
  // 'App-Client-Platform': Platform.OS,
  // 'App-Client-Version': encodeRFC5987ValueChars(env.versionNumber),
  // 'App-Client-Build': DeviceInfo.getBuildNumber(),
  // 'App-Client-Locale': ,
  'X-App-Name': 'xeniapi',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
};

export const authHeaders = (authToken: string) => {
  return {
    ...defaultHeaders,
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: `Bearer ${authToken}`,
  };
};
