import styles from "./styles/CustomButton.module.css";
import { FaChevronRight } from "react-icons/fa";
import { BounceLoader } from "react-spinners";

type Props = {
  type?: "primary" | "secondary" | "link" | "active" | "disabled";
  text: string;
  showSpinner?: boolean;
  showChevron?: boolean;
  isForm?: boolean;
  disabled?: boolean;
  onClick?: (event?: any) => void | Promise<void>;
  containerStyles?: string;
};

const CustomButton = (props: Props) => {
  const {
    type = "primary",
    text,
    showSpinner = false,
    showChevron = false,
    isForm = false,
    disabled,
    onClick,
    containerStyles = "",
  } = props;

  return (
    <div className={containerStyles}>
      <button
        type={isForm ? "submit" : "button"}
        className={`${styles.button} ${styles[type]}`}
        disabled={disabled || showSpinner}
        onClick={onClick}
      >
        <div></div>
        {text}
        {showChevron && showSpinner && (
          <div className={styles.spinner}>
            <BounceLoader color={"#000000"} size={12} />
          </div>
        )}
        {showChevron && !showSpinner && (
          <div className={styles.iconContainer}>
            <FaChevronRight color={"#666666"} size={12} />
          </div>
        )}
      </button>
    </div>
  );
};

export default CustomButton;
