"use client";
import { PacmanLoader } from "react-spinners";
import useDashboard from "./hooks/useDashboard";
import styles from "./styles/Dashboard.module.css";
import { FaCheckSquare, FaCross } from "react-icons/fa";

export default function Dashboard() {
  const { profileData, contactMessages, fetchingContactMessages } =
    useDashboard();

  return (
    <div>
      <p className={styles.welcomeMessage}>
        Welcome to Xeniapps! {profileData?.name}
      </p>
      <div className={styles.info}>
        <div className={styles.dataModule}>
          <h2 className={styles.subtitle}>Contact Messages</h2>
          <div className={styles.data}>
            {fetchingContactMessages ? (
              <PacmanLoader />
            ) : !contactMessages.length ? (
              <p>No Messages</p>
            ) : (
              contactMessages.map((message) => (
                <div className={styles.dataItem} key={message.id}>
                  <p>{message.name}</p>
                  <p>{message.email}</p>
                  <div>
                    {message.responded ? <FaCheckSquare /> : <FaCross />}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
