import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import App from "./app/App";
import LoginPage from "./app/login/Login";
import ErrorPage from "./app/error/ErrorPage";
import Dashboard from "./app/dashboard/Dashboard";
import ProtectedRoutes from "./app/ProtectedRoutes";
import SettlementsPage from "./app/settlements/Settlements";
import RegisterPage from "./app/register/Register";
import BusinessesPage from "./app/businesses/BusinessesPage";
import AddMultiple from "./app/businesses/[id]/add-multiple/AddMultiple";
import Settings from "./app/settings/Settings";
import Suggestions from "./app/suggestions/Suggestions";
import ContactMessage from "./app/contact-messages/ContactMessage";
// import Root, { rootLoader } from "./routes/root";
// import Team, { teamLoader } from "./routes/team";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    id: "home",
    errorElement: <ErrorPage />,
  },
  {
    path: "/stumblr",
    element: <App />,
    id: "stumblr",
  },
  {
    path: "/caffeind",
    element: <App />,
    id: "caffeind",
  },
  {
    path: "/tastd",
    element: <App />,
    id: "tastd",
  },
  {
    path: "login",
    element: <LoginPage />,
    id: "login",
  },
  {
    path: "register",
    element: <RegisterPage />,
    id: "register",
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settlements",
        element: <SettlementsPage />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/suggestions",
        element: <Suggestions />,
      },
      {
        path: "/businesses/:id/:type",
        element: <BusinessesPage />,
      },
      {
        path: "/businesses/:id/:type/add-json",
        element: <AddMultiple />,
      },
      {
        path: "/contact-message/:id/",
        element: <ContactMessage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
